import { ResponseResult } from '@/infra/api/core/request';
import {
  CacheTransformFederatedSearchParams,
  FederatedSearchCache,
  FederatedSearchCacheByContext,
  FederatedSearchContext,
  FederatedSearchContextPages,
  FederatedSearchResponse,
  FederatedSearchVariables,
} from '../../types/FederatedSearch';
import { Facet } from '../../types/shared';
import { mapDataByContext, mapInputByContext, paginateSearch } from './helpers';

export const mapFacetsToManageUpdateAndPersistance = ({
  loading,
  current,
  facets,
}: {
  loading: boolean;
  current?: Pick<
    FederatedSearchCacheByContext<FederatedSearchContext>,
    'facets' | 'initialFacets'
  >;
  facets?: Facet[];
}) => {
  if (loading) return current?.facets;

  return current?.facets || facets;
};

export const mapFederatedSearchToInitialContext = (
  result?: FederatedSearchCache
): FederatedSearchContext | undefined => {
  if (result?.loading) return undefined;

  const ContextsOrders = [
    FederatedSearchContext.Product,
    FederatedSearchContext.Deal,
    FederatedSearchContext.Store,
  ];

  const totals = {
    [FederatedSearchContext.Product]: !!result?.products.total,
    [FederatedSearchContext.Deal]: !!result?.deals.total,
    [FederatedSearchContext.Store]: !!result?.stores.total,
  };

  const filtered = ContextsOrders.filter(
    (context) => totals[context as FederatedSearchContextPages]
  );

  const initialContext = result?.mainContext || filtered[0];

  if (!initialContext) {
    return FederatedSearchContext.Product;
  }

  return initialContext as FederatedSearchContext | undefined;
};

export const cacheTransformFederatedSearchDeals = (
  params: CacheTransformFederatedSearchParams<FederatedSearchContext.Deal>
): FederatedSearchCache['deals'] => {
  const { current, data, input, loading } = params;

  const shouldUpdateCurrent = !!input;

  if (current && !shouldUpdateCurrent) return current;

  const edges = paginateSearch<FederatedSearchContext.Deal>({
    current,
    incomming: data,
    input,
  });

  const paginated = !!input?.after;

  return {
    currentTerm: input?.query || '',
    loading: loading && !paginated,
    paginationLoading: loading && paginated,
    pageInfo: data?.pageInfo,
    edges,
    facets: current?.facets || data?.facets,
    total: (loading ? current?.total : data?.total) || 0,
    originalEdges: data?.edges || [],
    searchId: data?.searchId || '',
  };
};

export const cacheTransformFederatedSearchProducts = (
  params: CacheTransformFederatedSearchParams<FederatedSearchContext.Product>
): FederatedSearchCache['products'] => {
  const { current, data, input, loading } = params;

  const shouldUpdateCurrent = !!input;

  if (current && !shouldUpdateCurrent) return current;

  const edges = paginateSearch<FederatedSearchContext.Product>({
    current,
    input,
    incomming: data,
  });

  const currentTerm = current?.currentTerm || '';

  const sameSearchTerm = input?.query === currentTerm;

  const paginated = !!input?.after;

  const facets = sameSearchTerm
    ? mapFacetsToManageUpdateAndPersistance({
        loading,
        current,
        facets: data?.facets,
      })
    : data?.facets;

  const initialFacets = !current?.initialFacets
    ? data?.facets
    : current?.initialFacets;

  return {
    indexSource: data?.indexSource,
    currentTerm: input?.query || '',
    loading: loading && !paginated,
    paginationLoading: loading && paginated,
    pageInfo: data?.pageInfo,
    edges,
    facets,
    total: (loading ? current?.total : data?.total) || 0,
    originalEdges: data?.edges || [],
    searchId: data?.searchId || '',
    initialFacets,
  };
};

export const cacheTransformFederatedSearchStores = (
  params: CacheTransformFederatedSearchParams<FederatedSearchContext.Store>
): FederatedSearchCache['stores'] => {
  const { current, data, input, loading } = params;
  const shouldUpdateCurrent = !!input;

  if (current && !shouldUpdateCurrent) return current;

  const edges = paginateSearch<FederatedSearchContext.Store>({
    current,
    incomming: data,
    input,
  });

  const paginated = !!input?.after;

  return {
    currentTerm: input?.query || '',
    loading: loading && !paginated,
    paginationLoading: loading && paginated,
    pageInfo: data?.pageInfo,
    edges,
    total: (loading ? current?.total : data?.total) || 0,
    originalEdges: data?.edges || [],
    searchId: data?.searchId || '',
  };
};

export function parseFederatedResultToCache(
  response: ResponseResult<
    FederatedSearchResponse['public']['federatedSearchV1'],
    FederatedSearchVariables
  >,
  current?: FederatedSearchCache
) {
  const dataByContext = mapDataByContext(response.data?.searches);
  const inputsByContext = mapInputByContext(response.variables?.input);

  return {
    deals: cacheTransformFederatedSearchDeals({
      current: current?.deals,
      data: dataByContext[FederatedSearchContext.Deal],
      input: inputsByContext[FederatedSearchContext.Deal],
      loading: response.loading,
    }),
    products: cacheTransformFederatedSearchProducts({
      current: current?.products,
      data: dataByContext[FederatedSearchContext.Product],
      input: inputsByContext[FederatedSearchContext.Product],
      loading: response.loading,
      facetsToIgnoreUpdate: response.variables?.facetsToIgnoreUpdate,
    }),
    stores: cacheTransformFederatedSearchStores({
      current: current?.stores,
      data: dataByContext[FederatedSearchContext.Store],
      input: inputsByContext[FederatedSearchContext.Store],
      loading: response.loading,
    }),
    currentTerm: response.variables?.input[0].query,
    mainContext: response.data?.rules?.mainContext,
    loading: response.loading,
  };
}
